import { Controller, useForm } from 'react-hook-form'
import z from 'zod'
import { zodResolver } from '@hookform/resolvers/zod'
import { useEffect, useRef, useState } from 'react'
import { Link } from './Link'
import { createShortlink } from '../utils/shortlink'

const schema = z.object({
  url: z.string({ required_error: 'Link é obrigatório' }).url('Link inválido'),
})

type Data = z.infer<typeof schema>

export const Form = () => {
  const { control, formState, handleSubmit, reset, setError, setValue, watch } =
    useForm<Data>({
      mode: 'onBlur',
      defaultValues: { url: '' },
      resolver: zodResolver(schema),
    })
  const ref = useRef<HTMLButtonElement>(null)
  const [links, setLinks] = useState<{ url: string; slug: string }[]>([])

  useEffect(() => {
    try {
      const storageLinks = localStorage.getItem('links')
      if (storageLinks) {
        const parsedLinks = JSON.parse(storageLinks)
        if (
          Array.isArray(parsedLinks) &&
          parsedLinks.every(link => link.url && link.slug)
        ) {
          setLinks(parsedLinks)
        }
      }
    } catch {
      // noop
    }
  }, [])

  const url = watch('url')

  const handlePaste: React.MouseEventHandler<HTMLButtonElement> = async () => {
    const text = await navigator.clipboard.readText()
    if (!url && text) {
      setValue('url', text)
      ref.current?.classList.add('animate')
      setTimeout(() => {
        ref.current?.classList.remove('animate')
      }, 800)
    }
  }

  const onSubmit = async (values: Data) => {
    try {
      const shortlink = await createShortlink(values.url)
      setLinks([...links.slice(-2), shortlink])
      localStorage.setItem('links', JSON.stringify([...links.slice(-2), shortlink]))
      reset()
    } catch (error) {
      setError('url', { message: 'Ocorreu um erro' })
    }
  }

  return (
    <>
      <form
        id="form"
        className="max-w-md w-full h-[72px]"
        onSubmit={handleSubmit(onSubmit)}
      >
        <div className="relative rounded-md shadow-sm">
          <Controller
            name="url"
            control={control}
            render={({ field }) => (
              <input
                type="text"
                className="block w-full rounded-md border-0 py-2 pr-32 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-orange-500"
                placeholder="https://encurta.com.br"
                onChange={field.onChange}
                onBlur={field.onBlur}
                name={field.name}
                value={field.value}
              />
            )}
          />
          <div className="absolute inset-y-0 right-0 flex items-center">
            <button
              type={url ? 'submit' : 'button'}
              className="flex justify-center rounded-r-md bg-orange-500 px-3 py-2 font-semibold leading-6 text-black hover:bg-orange-400 active:bg-orange-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-orange-500 cursor-pointer confetti disabled:opacity-50 w-[120px]"
              data-confetti-text="Link colado!"
              onClick={handlePaste}
              ref={ref}
              disabled={formState.isSubmitting}
            >
              {url ? 'Encurtar link' : 'Colar link'}
            </button>
          </div>
        </div>
        {formState.errors.url ? (
          <span className="text-red-500 text-sm">
            {formState.errors.url.message}
          </span>
        ) : null}
      </form>
      <ul className="flex flex-col gap-2 mt-3 max-w-md w-full">
        <li key="encurta" className="w-full">
          <Link url="https://encurta.com.br" slug="encurta" />
        </li>
        {links.map(link => (
          <li key={link.slug} className="w-full">
            <Link {...link} />
          </li>
        ))}
      </ul>
    </>
  )
}
