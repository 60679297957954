export const createShortlink = (
  url: string
): Promise<{ url: string; slug: string }> =>
  fetch(`https://api-v2.url.gratis/url`, {
    method: "POST",
    headers: {
      "content-type": "application/json",
    },
    body: JSON.stringify({ destination: url }),
  }).then(async (response) => {
    const data = await response.json();
    if (response.status >= 400) {
      throw new Error("Ocorreu um erro");
    }
    return { url, slug: data.slug };
  });
